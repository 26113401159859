import { PureComponent, type ReactNode } from 'react';
import { toaster, Message } from 'rsuite';
import Logger from '../../utils/Logger';

class ErrorBoundary extends PureComponent<
  { children: ReactNode },
  { hasError: boolean }
> {
  state = { hasError: false };

  componentDidCatch(error: Error, _errorInfo: unknown) {
    Logger.error(error);
    toaster.push(
      <Message showIcon type="error" title="Error">
        Sorry, something went wrong... Please try refreshing the page or contact
        our support...
      </Message>,
      { placement: 'topEnd', duration: 5000 },
    );
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
