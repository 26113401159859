import styled from 'styled-components';
import { mq } from '../../styles/mediaQueries';

export const SectionContainer = styled.div<{ $maxWidth?: string }>`
  position: relative;
  width: 100%;
  max-width: ${({ $maxWidth = '1224px' }) => $maxWidth};
  margin: 0 auto;
  padding: 40px;

  @media screen and (${mq.mobile}) {
    padding: 20px;
  }
`;
