import { type FC, useState, useEffect } from 'react';
import { NavBarDesktop } from './NavBarDesktop';
import { NavBarMobile } from './NavBarMobile';

import { useFirebase } from '../../context/firebase';
import ThemeSwitch from '../../common/ThemeSwitch';
import { HeaderNavbar, HeaderHomeLink } from './styles';
import { INTERNAL_URIS } from '../../constants/navigation';
import { ReactComponent as InvestoreanLogo } from '../../../static/investorean-logo-square-transparent.svg';
import { isDesktop } from '../../utils/env';

export const Header: FC = () => {
  const { user } = useFirebase();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <HeaderNavbar>
      <HeaderHomeLink
        to={INTERNAL_URIS.homepage.path}
        title={INTERNAL_URIS.homepage.title}
      >
        <InvestoreanLogo height={26} />
        {(!isLoaded || isDesktop()) && <span>Investorean</span>}
      </HeaderHomeLink>
      <ThemeSwitch />
      {isLoaded && !isDesktop() ? (
        <NavBarMobile user={user} />
      ) : (
        <NavBarDesktop user={user} />
      )}
    </HeaderNavbar>
  );
};
