import type { FC } from 'react';
import { Link } from 'gatsby';
import Telegram from '@rsuite/icons/legacy/Telegram';
import Reddit from '@rsuite/icons/legacy/Reddit';
import Instagram from '@rsuite/icons/legacy/Instagram';
import Facebook from '@rsuite/icons/legacy/FacebookSquare';
import LinkedIn from '@rsuite/icons/legacy/Linkedin';
import { ReactComponent as TwitterX } from '../../../static/icons/twitter-x.svg';
import { ReactComponent as TikTok } from '../../../static/icons/tiktok.svg';

import {
  FooterContent,
  SocialIcons,
  Navigation,
  LegalInformation,
} from './styles';
import { EXTERNAL_URLS, INTERNAL_URIS } from '../../constants/navigation';

export const Footer: FC = () => {
  return (
    <>
      <FooterContent>
        <Navigation>
          <li>
            <a
              href={INTERNAL_URIS.blog.path}
              title={INTERNAL_URIS.blog.title}
              target="_blank"
              rel="nofollow noreferrer"
            >
              Blog
            </a>
          </li>
          <li>
            <Link
              to={INTERNAL_URIS.contact.path}
              title={INTERNAL_URIS.contact.title}
            >
              Contacts
            </Link>
          </li>
          <li>
            <Link to={INTERNAL_URIS.faq.path} title={INTERNAL_URIS.faq.title}>
              FAQ
            </Link>
          </li>
          <li>
            <Link
              to={INTERNAL_URIS.terms.path}
              title={INTERNAL_URIS.terms.title}
            >
              Terms
            </Link>
          </li>
          <li>
            <Link
              to={INTERNAL_URIS.privacy.path}
              title={INTERNAL_URIS.privacy.title}
            >
              Privacy
            </Link>
          </li>
          <li>
            <a
              href={EXTERNAL_URLS.status.path}
              title={EXTERNAL_URLS.status.title}
              target="_blank"
              rel="nofollow noreferrer"
            >
              Status
            </a>
          </li>
        </Navigation>
        <SocialIcons>
          <li>
            <a
              href={EXTERNAL_URLS.twitter.path}
              title={EXTERNAL_URLS.twitter.title}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <TwitterX />
            </a>
          </li>
          <li>
            <a
              href={EXTERNAL_URLS.tiktok.path}
              title={EXTERNAL_URLS.tiktok.title}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <TikTok />
            </a>
          </li>
          <li>
            <a
              href={EXTERNAL_URLS.telegram.path}
              title={EXTERNAL_URLS.telegram.title}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <Telegram />
            </a>
          </li>
          <li>
            <a
              href={EXTERNAL_URLS.reddit.path}
              title={EXTERNAL_URLS.reddit.title}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <Reddit />
            </a>
          </li>
          <li>
            <a
              href={EXTERNAL_URLS.facebook.path}
              title={EXTERNAL_URLS.facebook.title}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <Facebook />
            </a>
          </li>
          <li>
            <a
              href={EXTERNAL_URLS.instagram.path}
              title={EXTERNAL_URLS.instagram.title}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <Instagram />
            </a>
          </li>
          <li>
            <a
              href={EXTERNAL_URLS.linkedin.path}
              title={EXTERNAL_URLS.linkedin.title}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <LinkedIn />
            </a>
          </li>
        </SocialIcons>
      </FooterContent>
      <LegalInformation>
        <div>Copyright &copy; {new Date().getFullYear()} Investorean</div>
      </LegalInformation>
    </>
  );
};
